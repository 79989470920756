<template>
  <div>
    <v-card
      v-if="!procesing"
      class="mx-auto mt-5 d-flex align-center flex-column"
    >
      <v-card-title class="pb-b"><h4>Transcription</h4></v-card-title>
      <v-card-text>
        <v-row
          class="d-flex justify-center align-center flex-column text-center"
        >
          <v-col>
            <audio :src="audioUrl" controls></audio>
            <v-textarea outlined counter v-model="text"></v-textarea>
          </v-col>
        </v-row>

        <v-row
          class="d-flex justify-center align-center flex-column text-center"
        >
          <v-col>
            <v-btn
              rounded
              color="primary"
              :disabled="!text"
              @click="processText"
            >
              <v-icon left>fa-project-diagram</v-icon>
              Graph me
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
      <!--
      <v-card-actions>
        <router-link :to="{ name: 'record' }" class="card-footer-item"
          >Or make a recording</router-link
        >
      </v-card-actions>
      -->
    </v-card>
    <Processing
      v-if="procesing"
      title="Psychomeasuring!"
      message="Please, be patient."
      icon="fa-cog"
    />
  </div>
</template>
<script>
import { mapState } from "vuex"
import Processing from "@/components/Processing.vue"

export default {
  name: "TextResults",
  components: { Processing },
  computed: {
    text: {
      get() {
        return this.$store.state.speechgraph.text
      },
      set(value) {
        this.$store.commit("speechgraph/text", value)
      },
    },
    audioUrl() {
      return URL.createObjectURL(this.$store.state.speechgraph.audio)
    },
    ...mapState({ procesing: state => state.speechgraph.procesing }),
  },
  filters: {
    nl2br: function (value) {
      if (!value) return ""
      value = value.toString()
      return value.replaceAll("\n", "<br>")
    },
  },
  methods: {
    processText() {
      this.$store.dispatch("speechgraph/processText")
    },
  },
}
</script>
